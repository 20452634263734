import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.css'],
  standalone: true,
  imports: [CommonModule, NgbModule],
})
export class DateRangePickerComponent {
  @Input()
  da: FormControl = new FormControl();
  @Input()
  al: FormControl = new FormControl();

  @Input()
  buttonClass = 'btn-outline-secondary';

  @Input()
  placeholder = { da: 'dd/mm/yyyy', al: 'dd/mm/yyyy' };

  constructor(
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
  ) {
  }

  // date range picker

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null = null;
  toDate: NgbDate | null = null;

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.da.setValue(this.ngbDateToDate(date));
    } else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      date.after(this.fromDate)
    ) {
      this.toDate = date;
      this.al.setValue(this.ngbDateToDate(date));
    } else {
      this.toDate = null;
      this.fromDate = date;
      this.al.setValue(null);
      this.da.setValue(this.ngbDateToDate(date));
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(
    currentValue: NgbDate | null,
    input: string,
    formControl?: FormControl,
  ): NgbDate | null {

    const parsed = this.formatter.parse(input);
    const resp =
      parsed && this.calendar.isValid(NgbDate.from(parsed))
        ? NgbDate.from(parsed)
        : null;

    if (resp) {
      formControl?.setValue(this.ngbDateToDate(resp));
    } else {
      formControl?.setValue(null);
    }
    return resp;
  }

  validateBefore(
    currentValue: NgbDate | null,
    input: string,
    compareWith: NgbDate | null,
    formControl?: FormControl,
  ) {
    const resp = this.validateInput(currentValue, input, formControl);

    const isValid = compareWith ? resp?.before(compareWith) : true;

    if (!input) {
      return resp;
    }

    if (isValid) {
      return resp;
    } else {
      return null;
    }
  }

  validateAfter(
    currentValue: NgbDate | null,
    input: string,
    compareWith: NgbDate | null,
    formControl?: FormControl,
  ): NgbDate | null {
    const resp = this.validateInput(currentValue, input, formControl);
    const isValid = compareWith ? resp?.after(compareWith) : true;
    if (isValid) {
      return resp;
    } else {
      return null;
    }
  }

  reset() {
    this.fromDate = null;
    this.toDate = null;
    this.da.reset();
    this.al.reset();
  }

  ngbDateToDate(ngbDate: NgbDate) {
    const date = new Date();
    date.setUTCFullYear(ngbDate.year, ngbDate.month - 1, ngbDate.day);
    return date;
  }

  backSpaceCheck($event: Event) {
    if (($event as KeyboardEvent).key === 'Backspace' && ($event.target as any).value === '') {
      this.da.setValue(null);
    }
  }
}
